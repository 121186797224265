.lMain{
    background-color: #ffffff;
    background-image: url("img/laurenBg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    
    font-size: 20px;
    text-align: center;
    padding: 50%;
    z-index: 100;

}

.logo {
    border-radius: 50%;
    height: 120px;
    width: auto;
    padding-top: 10px;
}

.lName {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 28px;
}

.icon {
    height: 35px;
}

.buttonLabel {
    font-weight: bold;
}