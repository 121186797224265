.modCheck {
    width: 300px;
    height: auto;
}

.App {
    background-color: #000000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    
    font-size: 20px;
    text-align: center;
    padding: 100%;
    z-index: 100;

}

.errorText {
    color: #ffffff;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 30px;
}